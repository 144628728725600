import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import SimpleCard from "../components/SimpleCard";

import { startupData } from "../redux/actions/action";

const StartUp = () => {
  let startup = useSelector((state) => state.startup);
  const dispatch = useDispatch();
  const [startupDatas, setStartUpDatas] = useState();

  let startups = startup.startups.data;

  useEffect(() => {
    if(startups){
      setData(startups);
    }
  }, [startups]); 
  
  const setData = (data) => {
    setStartUpDatas(data);
  };

  const onDataFilter = (keyWord,  starthear, endhear) => {
    let filteredText = startups;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 <= timestampT1
      }
      );
    }

    setStartUpDatas(filteredText);
    console.log("On research", filteredText);
  }

  React.useEffect(() => {
    dispatch(startupData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Startups" onFilter={onDataFilter} />
      <div className="container">
        <SimpleCard data={startupDatas} />
      </div>
      <NewLetter />
      <Footer />
    </>
  );
};

export default StartUp;
