import React from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import HomeCard from "../components/HomeCard";
import NewLetter from "../components/NewLetter";

import { recentProjectData, actualityData } from "../redux/actions/action";

const Home = () => {
  let project = useSelector((state) => state.project);
  let actuality = useSelector((state) => state.actuality);

  console.log("project: ", project);
  console.log("actuality: ", actuality);

  let projectsData = project?.projects?.data;
  let actualitiesData = actuality?.actualities?.data;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(recentProjectData());
    dispatch(actualityData());
  }, [dispatch]);

  console.log("project: ", project);
  console.log("actuality: ", actuality);

  return (
    <>
      <Navbar />
      <Banner />
      <HomeCard title="Projets récents" data={projectsData} />
      <HomeCard title="Actualités" data={actualitiesData} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Home;
