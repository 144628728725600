import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import MediumExtraCard from "../components/MediumExtraCard";

import { economiePromotionData } from "../redux/actions/action";

const EconomiquePromotion = () => {
  let economiquePromotion = useSelector((state) => state.economiquePromotion);
  const dispatch = useDispatch();

  console.log("economiquePromotion: ", economiquePromotion);

  const [economiquePromotionData, setEconomiquePromotionsData] = useState();

  let economiquePromotions = economiquePromotion?.economiePromotions?.data;

  useEffect(() => {
    if(economiquePromotions){
      setData(economiquePromotions);
    }
  }, [economiquePromotions]); 
  
  const setData = (data) => {
    setEconomiquePromotionsData(data);
  };

  const onDataFilter = (keyWord,  starthear, endhear) => {
    let filteredText = economiquePromotions;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 <= timestampT1
      }
      );
    }

    setEconomiquePromotionsData(filteredText);
    console.log("On research", filteredText);
  }

  useEffect(() => {
    dispatch(economiePromotionData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Promotion Economie Numérique" onFilter={onDataFilter} />
      <MediumExtraCard data={economiquePromotionData} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default EconomiquePromotion;
