import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { globalProjectData } from "../redux/actions/action";

const RealiseProject = () => {
  let realiseProject = useSelector((state) => state.globalProject);
  const dispatch = useDispatch();

  const [realiseProjectDatas, setRealiseProjectDatas] = useState();

  console.log("strategiquePlan: ", realiseProject);

  let realiseProjects = realiseProject?.globalProjects?.data;

  // let strategiquePlansDocument = strategiquePlans?.filter(
  //   (item) => item.media.length === 0
  // );

  // let strategiquePlansText = strategiquePlans?.filter(
  //   (item) => item.media.length > 0
  // );

  useEffect(() => {
    if(realiseProjects){
      setData(realiseProjects);
    }
  }, [realiseProjects]); 
  
  const setData = (data) => {
    setRealiseProjectDatas(data);
  };

  const onDataFilter = (doc, keyWord,  starthear, endhear) => {
    let filteredText = realiseProjects;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 <= timestampT1
      }
      );
    }

    setRealiseProjectDatas(filteredText);
    console.log("On research", filteredText);
  }

  React.useEffect(() => {
    dispatch(globalProjectData("projet-realise"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Projets réalisés" onFilter={onDataFilter} />
      <GlobalCard textData={realiseProjectDatas} documentData={realiseProjectDatas} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default RealiseProject;
