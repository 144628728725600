import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { repportData } from "../redux/actions/action";

const Repport = () => {
  let repport = useSelector((state) => state.repport);
  const dispatch = useDispatch();

  const [repportDocument, setRepportDocument] = useState();
  const [repportText, setRepportText] = useState();

  let repports = repport.repports.data;

  console.log("repports: ", repports);

  useEffect(() => {
    if (repports) {
      filterData(repports);
    }
  }, [repports]);

  const filterData = (data) => {
    const filteredText = data.filter((item) => item.type === "text");
    const filteredDocument = data.filter((item) => item.type === "document");
    setRepportText(filteredText);
    setRepportDocument(filteredDocument);
  };

  const onDataFilter = (doc, keyWord, starthear, endhear) => {
    let filteredText = repports.filter((item) => item.type === "text");
    let filteredDocument = repports.filter((item) => item.type === "document");

    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
      filteredDocument = filteredDocument?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        return timestampT2 >= timestampT1
      }
      );
      filteredDocument = filteredDocument?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        return timestampT2 <= timestampT1
      }
      );
      filteredDocument = filteredDocument?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        return timestampT2 <= timestampT1
      }
      );
    }

    setRepportText(filteredText);
    setRepportDocument(filteredDocument);
    console.log("On research", filteredText);
  }

  useEffect(() => {
    dispatch(repportData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Rapports d’activités" onFilter={onDataFilter}/>
      <GlobalCard textData={repportText} documentData={repportDocument} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Repport;
