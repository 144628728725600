import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";

import SingleDetailPage from "./SingleDetailsPage";

import { truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

const CardTutelle = ({ data }) => {
  const [cardDetails, setCardDetails] = useState(null);

  return (
    <>
      {cardDetails === null && (
        <div className="container pt-11">
          {data?.map((item, index) => (
            <div className="row grow" key={index}>
              <h2 className="title">{item?.name}</h2>
              {item?.directions?.map((elemt, index) => (
                <div className="col-lg-4 mb-4" key={index}>
                  <div className="header pl-0 pr-0">
                    <h3>{elemt?.name}</h3>
                    <div className="grow-content">
                      <p className="director">
                        <span>Directeur Général :</span> {elemt?.director_name}
                      </p>
                      <p>{truncate(elemt?.description, 150)}</p>
                      <a
                        className="link-fill"
                        onClick={() => setCardDetails(elemt)}
                        role="button"
                      >
                        Lire la plus <FaChevronRight />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
    </>
  );
};

export default CardTutelle;
