import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { post } from "../helpers/api_helper";
import { POST_SUBCRIBE_TO_NEWLETTER } from "../helpers/url_helper";

const NewLetter = () => {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [requestError, setRequestError] = useState("");
  const [sucess, setSucess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestError(null);
    setSucess(null)
    if (email === "" || name === "") {
      setRequestError("* Nom ou email manquant !")
    } else {

      try {
        const response = await post(POST_SUBCRIBE_TO_NEWLETTER, { email, name });
        setSucess(response.message);
        setName("");
        setEmail("");
      } catch (error) {
        setRequestError("L'abonnement a échoué veillez réessayer à nouveau !")
        console.log("Abonnement error ", error);
      }
    }

  }

  return (
    <section className="newsletter pb-11">
      <div className="container">
        <div className="row mt-7">
          <div className="col-lg-12 text-center">
            <span className="mb-6">Ne ratez aucune information</span>
            <h2>Abonnez-vous à notre newsletter</h2>
          </div>
          <div className="col-lg-12 col-xl-12 mx-auto">
            <Form onSubmit={handleSubmit}>
              <Row className="formular">
                <Col lg={5} md={6} className="box-control">
                  <Form.Control type="text" placeholder="Nom et prénom" value={name} onChange={(e) => { setName(e.target.value) }} className="w-100" />
                </Col>

                <Col lg={5} md={6} className="box-control">
                  <Form.Control type="email" placeholder="Email" className="w-100" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                </Col>

                <Col lg={2} md={12} className="text-center mt-lg-0 mt-md-4 text-lg-end">
                  <Button type="submit" >
                    S'abonner
                  </Button>
                </Col>
                {requestError && <p className="text-white text-center pt-3 mb-0">{requestError}</p>}
                {sucess && <p className="text-white text-center pt-3 mb-0">{sucess}</p>}
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewLetter;
