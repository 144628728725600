import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

import SingleDetailPage from "./SingleDetailsPage";
import Pagination from "./Pagination";

import { splitList, truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";
import Skeleton from "react-loading-skeleton";
import { MapFill } from "react-bootstrap-icons";

const ArchiveCard = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [cardDetails, setCardDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [splitedData, setSplitedData] = useState([]);

  console.log("data: ", data);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.length / 10));
      setSplitedData(splitList(data, 10));
      setLoading(false);
    }
  }, [data]);

  let renderPagination = () => {
    if (path.match("/archive-du-ministere")) {
      return (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      );
    }
  };
  let renderLoadingItem = () => {
    return (
      <div className="col-lg-4 mb-4">
        <div className="agenda-box">
          <Skeleton height={200} />

          <Skeleton width={200} />

          <Skeleton width={150} />

          {path.startsWith("/agenda-du-ministre") && (
            <p>
              {" "}
              <Skeleton count={2} />{" "}
            </p>
          )}
          {path === "/agenda" && (
            <div>
              {loading ? (
                <Skeleton width={80} />
              ) : (
                <span>
                  <MapFill className="mr-2" />
                  <Skeleton width={100} />
                </span>
              )}
            </div>
          )}
          <Skeleton width={100} />
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      {cardDetails === null && (
        <div className="row grow mt-7 pt-7">
          {data?.length === 0 && (
            <p className="text-center">
              Aucune donnée disponible pour le moment. Veuillez réessayer plus
            </p>
          )}

          {loading
            ? Array.from({ length: 3 }).map((_, index) => (
                <>{renderLoadingItem()}</>
              ))
            : splitedData[currentPage - 1]?.map((elemt, index) => {
                let imageUrl = elemt?.media?.find(
                  (media) =>
                    media.mime_type === "image/jpeg" ||
                    media.mime_type === "image/png" ||
                    media.mime_type === "image/jpg"
                );
                return (
                  <div className="col-lg-4 mb-4" key={index}>
                    <div className="archive-box">
                      <img
                        src={imageUrl?.original_url}
                        className="img-fluid w-100 pb-2"
                        alt=""
                      />
                      <h3 className="py-2">
                        {elemt.firstname + " " + elemt.lastname}
                      </h3>
                      <p>{truncate(elemt.poste, 35)}</p>
                      <button
                        className="link-archive"
                        onClick={() => setCardDetails(elemt)}
                      >
                        Lire la suite <FaChevronRight />
                      </button>
                    </div>
                  </div>
                );
              })}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
      {cardDetails === null && (
        <>
          {data?.length > 0 && !loading && (
            <div className="row">{renderPagination()}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ArchiveCard;
