import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { globalProjectData } from "../redux/actions/action";

const InPorgressProject = () => {
  let inprogressProject = useSelector((state) => state.globalProject);
  const dispatch = useDispatch();

  const [inprogressProjectDatas, setInprogressProjectDatas] = useState();

  console.log("strategiquePlan: ", inprogressProject);

  let inprogressProjects = inprogressProject?.globalProjects?.data;

  // let strategiquePlansDocument = strategiquePlans?.filter(
  //   (item) => item.media.length === 0
  // );

  // let strategiquePlansText = strategiquePlans?.filter(
  //   (item) => item.media.length > 0
  // );

  useEffect(() => {
    if(inprogressProjects){
      setData(inprogressProjects);
    }
  }, [inprogressProjects]); 
  
  const setData = (data) => {
    setInprogressProjectDatas(data);
  };

  const onDataFilter = (doc, keyWord,  starthear, endhear) => {
    let filteredText = inprogressProjects;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
        return timestampT2 <= timestampT1
      }
      );
    }

    setInprogressProjectDatas(filteredText);
    console.log("On research", filteredText);
  }

  React.useEffect(() => {
    dispatch(globalProjectData("projet-realise"));
  }, [dispatch]);

  //projet-en-cour-de-realisation

  return (
    <>
      <Navbar />
      <BannerCurrent title="Projets en cours de réalisation" onFilter={onDataFilter}/>
      <GlobalCard textData={inprogressProjectDatas} documentData={inprogressProjectDatas} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default InPorgressProject;
