import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

import SingleDetailPage from "./SingleDetailsPage";
import Pagination from "./Pagination";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import "../App.css";
import "../theme.css";
import { splitList } from "../helpers/global_helper";

const CardOpportunity = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [cardDetails, setCardDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [splitedData, setSplitedData] = useState([]);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.length / 10));
      setSplitedData(splitList(data, 10));
      setLoading(false);
    }
  }, [data]);

  let renderLoadingBlock = () => {
    return (
      <div className=" col-lg-12 mb-4">
        <div className="bloc-opportunity">
          <Skeleton width={200} />
          <Skeleton count={2} />
          <Skeleton width={100} />
        </div>
      </div>
    );
  };

  let renderPagination = () => {
    if (path.match("/opportunites")) {
      return (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      );
    }
  };
  return (
    <div className="container py-11">
      {cardDetails === null && (
        <div className="row opportunity">
          {loading
            ? Array.from({ length: 3 }).map((_, index) => (
                <>{renderLoadingBlock()}</>
              ))
            : splitedData[currentPage - 1]?.map((item, index) => (
                <div className=" col-lg-12 mb-4" key={index}>
                  <div className="bloc-opportunity">
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                    <button
                      className="link-fill"
                      onClick={() => setCardDetails(item)}
                    >
                      En savoir plus <FaChevronRight />
                    </button>
                  </div>
                </div>
              ))}
          {data?.length > 0 && !loading && <>{renderPagination()}</>}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
    </div>
  );
};

export default CardOpportunity;
