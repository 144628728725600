import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";
import "../theme.css";

import HomeImage1 from "../assets/images/accueil/accueil_plateforme_mten.jpg";
import HomeImage2 from "../assets/images/a_propos/a_propos.jpg";
import HomeImage3 from "../assets/images/communications/actualite.jpg";

const Banner = () => {
  const images = [HomeImage1, HomeImage2, HomeImage3];
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next)
  };

  return (
    <section className="">
      <div className="container-fluid px-0">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <div className="banner align-items-center flex-column"
                style={{ backgroundImage: `url(${image})` }}>
                <div className="text-banner mx-11">
                  <h1>
                    L'essentiel à savoir sur le développement du numérique au
                    Tchad
                  </h1>
                  
                </div>
                <div className="btn-banner mx-11">Lire l'article</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Banner;
