import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import MediumCard from "../components/MediumCard";

import "../App.css";
import "../theme.css";

import { plateformeData } from "../redux/actions/action";

const Plateforme = () => {
  let plateform = useSelector((state) => state.plateforme);
  const dispatch = useDispatch();

  const [platfomrData, setPlatformData] = useState();

  let plateforms = plateform?.plateformes?.data;

  useEffect(() => {
    if(plateforms){
      setData(plateforms);
    }
  }, [plateforms]); 
  
  const setData = (data) => {
    setPlatformData(data);
  };

  const onDataFilter = (keyWord, hear) => {
    let filteredText = plateforms;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (hear) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );
    }

    setPlatformData(filteredText);
    // console.log("On research", filteredText);
  }

  useEffect(() => {
    dispatch(plateformeData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Plateformes"  onFilter={onDataFilter} />
      <MediumCard data={platfomrData} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Plateforme;
