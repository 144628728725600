import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import MediumExtraCard from "../components/MediumExtraCard";

import { sensibilisationData } from "../redux/actions/action";

const Sensibilisation = () => {
  let sensibilisation = useSelector((state) => state.sensibilisation);
  const dispatch = useDispatch();

  const [sensibilisationDatas, setSensibilisationDatas] = useState();

  console.log("opportunity: ", sensibilisation);

  useEffect(() => {
    if(sensibilisationDatas){
      setData(sensibilisationDatas);
    }
  }, [sensibilisationDatas]); 
  
  const setData = (data) => {
    setSensibilisationDatas(data);
  };

  useEffect(() => {
    dispatch(sensibilisationData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Sensibilisation" />
      <MediumExtraCard data={sensibilisationDatas} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Sensibilisation;
