import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import SingleDetailPage from "./SingleDetailsPage";
import Pagination from "./Pagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { splitList, truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

import { FaChevronRight } from "react-icons/fa";
import FormationImg from "../assets/images/formation.png";

const TrainingCard = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [cardDetails, setCardDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [splitedData, setSplitedData] = useState([]);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.length / 10));
      setSplitedData(splitList(data, 10));
      setLoading(false);
    }
  }, [data]);

  let renderLoadingItem = () => {
    return (
      <div className=" grow-formation col-lg-12 mb-4">
        <div className="row d-flex align-items-center">
          <div className="col-md-4">
            <Skeleton height={200} />
          </div>
          <div className="col-md-8">
            <Skeleton width={100} />
            <Skeleton width={200} />
            <Skeleton count={4} />
            <Skeleton width={100} />
          </div>
        </div>
      </div>
    );
  };

  let renderPagination = () => {
    if (path.match("/formation")) {
      return (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      );
    }
  };

  return (
    <div className="container">
      {cardDetails === null && (
        <div className="formation row mt-11">
          {loading
            ? Array.from({ length: 3 }).map((_, index) => (
                <>{renderLoadingItem()}</>
              ))
            : splitedData[currentPage - 1]?.map((item, index) => (
                <div className=" grow-formation col-lg-12 mb-4" key={index}>
                  <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                      {loading ? (
                        <Skeleton height={200} />
                      ) : (
                        <img src={FormationImg} className="img-fluid" alt="" />
                      )}
                    </div>
                    <div className="col-md-8">
                      {loading ? (
                        <Skeleton width={100} />
                      ) : (
                        <span>{item?.category?.name}</span>
                      )}
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        <h2>{item?.title}</h2>
                      )}

                      {loading ? (
                        <Skeleton count={4} />
                      ) : (
                        <p>{truncate(item?.description, 200)}</p>
                      )}

                      {loading ? (
                        <Skeleton width={100} />
                      ) : (
                        <button
                          className="link-fill"
                          onClick={() => setCardDetails(item)}
                        >
                          Lire <FaChevronRight />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
      {cardDetails === null && (
        <>
          {data?.length > 0 && <div className="row">{renderPagination()}</div>}
        </>
      )}
    </div>
  );
};

export default TrainingCard;
