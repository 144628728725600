import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Share, MapFill, ClockFill } from "react-bootstrap-icons";
import { FaChevronRight } from "react-icons/fa";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Pagination from "./Pagination";

import { truncate, dateFormat, splitList } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

const AgendaCard = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [splitedData, setSplitedData] = useState([]);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.length / 10));
      setSplitedData(splitList(data, 10))
      setLoading(false);
    }
  }, [data]);

  let renderLoadingItem = () => {
    return <div className="col-lg-4 mb-4" >
      <div className="agenda-box">
        <Skeleton height={200} />

        <Skeleton width={200} />

        <Skeleton width={150} />

        {path.startsWith("/agenda-du-ministre") && (
          <p> <Skeleton count={2} /> </p>
        )}
        {path === "/agenda" && (
          <div>
            {loading ? <Skeleton width={80} />
              : (
                <span>
                  <MapFill className="mr-2" />
                  <Skeleton width={100} />
                </span>
              )}
          </div>
        )}
        <Skeleton width={100} />
      </div>
    </div>
  }

  let renderPagination = () => {
    if (path.startsWith("/agenda-du-ministre") || path.startsWith("/agenda")) {
      return <Pagination totalPage={totalPage} currentPage={currentPage} onPageChange={setCurrentPage} />;
    }
  };

  return (
    <div className="container pt-11">
      <div className="row">
        {data?.length === 0 && (
          <p className="text-center pt-11">
            Aucune donnée disponible pour le moment. Veuillez réessayer plus
          </p>
        )}
        {loading ?
          Array.from({ length: 3 }).map((_, index) => (
            <>{renderLoadingItem()}</>
          ))
          : splitedData[currentPage - 1]?.map((item, index) => {
            return (
              <div className="col-lg-4 mb-4" key={index}>
                <div className="agenda-box">
                  <img
                    src={item?.media?.[0]?.original_url || ""}
                    height="30"
                    className="img-fluid w-100 pb-2"
                    alt="Project"
                  />
                  <h3 className="py-2">
                    {!path.startsWith("/agenda-du-ministre") && (
                      <ClockFill className="mr-2" />
                    )}
                    Du {dateFormat(item?.start_date)} au{" "}
                    {dateFormat(item?.end_date)}
                  </h3>
                  <h5>{truncate(item?.title, 35)}</h5>
                  {path.startsWith("/agenda-du-ministre") && (
                    <p> truncate(item?.description, 100)</p>
                  )}
                  {path === "/agenda" && (
                    <div>
                      {loading ? <Skeleton width={80} /> : (
                      <span>
                        <MapFill className="mr-2" />
                        {loading ? <Skeleton width={100} /> : item?.location}
                      </span>
                    )}
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-between">
                    <span>
                      <Share />
                    </span>
                    <span>
                      <a className="link-agenda" href="#">
                        Lire la suite <FaChevronRight />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {data?.length > 0 && <div className="row">{renderPagination()}</div>}
    </div>
  );
};

export default AgendaCard;
