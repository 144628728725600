import React from "react";
import "../App.css";
import "../theme.css";

import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const Pagination = ({totalPage, currentPage, onPageChange}) => {

  const handleCurrentElement = (action) =>{
    let page = currentPage
    if(action == "next" && page < totalPage ){
      page += 1;  
    } 
    
      if( action == "prev" && page > 1)
      {page -= 1}
    
    onPageChange(page)
  }


  return (
    <div className="container pt-7">
      <div className="paginate d-flex align-item-center justify-content-between">
        <div className="left" >
          <a className="btn-left btn" onClick={() => handleCurrentElement("prev")}>
            <FaChevronLeft />
            Précédent
          </a>
        </div>
        <div className="center">
          <span>{currentPage} sur {totalPage}</span>
        </div>
        <div className="right">
          <a className="btn-right btn" onClick={() => handleCurrentElement("next")}>
            Suivant
            <FaChevronRight />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
