import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import ExtraCard from "../components/ExtraCard";

import "../App.css";
import "../theme.css";

import { juriCategoryDocumentData } from "../redux/actions/action";

const Law = () => {
  let law = useSelector((state) => state.juriCategoryDocument);
  const dispatch = useDispatch();

  const [lawDatas, setLowDatas] = useState();

  let laws = law?.juriCategoryDocuments?.data?.document;

  // console.log("laws: ", laws?.document);

  useEffect(() => {
    if(laws){
      setData(laws);
    }
  }, [laws]); 
  
  const setData = (data) => {
    setLowDatas(data);
  };

  const onDataFilter = (doc, keyWord,  starthear, endhear) => {
    let filteredText = laws;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 <= timestampT1
      }
      );
    }

    setLowDatas(filteredText);
    console.log("On research", filteredText);
  }

  useEffect(() => {
    dispatch(juriCategoryDocumentData("lois"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Liste des lois" onFilter={onDataFilter} />
      <ExtraCard data={lawDatas} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Law;
