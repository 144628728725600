import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavbarItems } from "../common/data/navbar";
import logo from "../assets/images/LOGO_MCENDA_TCHAD_sans_arrière_plan1.png";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

function NavItem({ item }) {
  const hasSubMenu = item.items && item.items.length > 0;

  return hasSubMenu ? (
    <NavDropdown title={item.label} id={`nav-dropdown-${item.label}`}>
      {item.items.map((subItem, subIndex) => (
        <React.Fragment key={subIndex}>
          {subItem.items ? (
            <NavDropdown
              title={subItem.label}
              id={`nav-sub-dropdown`}
              className={subIndex === 0 ? "first-sub-menu" : "second-sub-menu"}
            >
              <div
                className={
                  subIndex === 0
                    ? "first-sub-menu-items"
                    : "second-sub-menu-items"
                }
              >
                {subItem.items.map((nestedSubItem, nestedSubIndex) => (
                  <NavDropdown.Item
                    key={nestedSubIndex}
                    href={`${nestedSubItem.path}`}
                  >
                    {nestedSubItem.label}
                  </NavDropdown.Item>
                ))}
              </div>
            </NavDropdown>
          ) : (
            <NavDropdown.Item href={`${subItem.path}`}>
              {subItem.label}
            </NavDropdown.Item>
          )}
        </React.Fragment>
      ))}
    </NavDropdown>
  ) : (
    <NavDropdown.Item href={`${item.path}`} className="no-submenu-item">
      {item.label}
    </NavDropdown.Item>
  );
}

function NavBar() {
  return (
    <Navbar collapseOnSelect expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={logo}
            height="60"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            {NavbarItems.map((item, index) => (
              <NavItem key={index} item={item} />
            ))}
          </Nav>
          <Nav>
            <Nav className="navbar-icons">
              <div className="social-icon">
                <a href="#">
                  <FaLinkedin size={25} color="#fff" />
                </a>
              </div>
              <div className="social-icon">
                <a href="#">
                  <FaTwitter size={25} color="#fff" />
                </a>
              </div>
              <div className="social-icon">
                <a href="#">
                  <FaInstagram size={25} color="#fff" />
                </a>
              </div>
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
