import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import CardTutelle from "../components/CardEntyTutelle";
import { entitiesData } from "../redux/actions/action";

const TunelEntity = () => {
    let entitie = useSelector((state) => state.entity);
    const dispatch = useDispatch();

    const [entitieText, setentitieText] = useState();


    const onDataFilter = (keyWord, sigle) => {
        let filteredText = entitieText.filter((item) => item.type === "text");

        if (keyWord) {
            filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
                value =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(keyWord.toLowerCase())
            )
            );
        }

        if (sigle) {
            filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
                value =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(sigle.toLowerCase())
            )
            );
        }
        setentitieText(filteredText);
    }

    useEffect(() => {
        if (entitie) {
            let entitiesDatas = entitie.entities.data;
            setentitieText(entitiesDatas);
        }
    }, [entitie]);

    React.useEffect(() => {
        dispatch(entitiesData());
    }, [dispatch]);

    return (
        <>
            <Navbar />
            <BannerCurrent title="Entités sous tutelles" onFilter={onDataFilter} />
            <CardTutelle data={entitieText} />
            <NewLetter />
            <Footer />
        </>
    );
};

export default TunelEntity;
