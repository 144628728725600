import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { globalProjectData } from "../redux/actions/action";

const StrategiquePlan2030 = () => {
  let strategiquePlan = useSelector((state) => state.globalProject);
  const dispatch = useDispatch();
  const [strategiquePlansData, setStrategiquePlansData] = useState();

  console.log("strategiquePlan: ", strategiquePlan);

  let strategiquePlans = strategiquePlan?.globalProjects?.data;

  useEffect(() => {
    if(strategiquePlans){
      setStrategiquePlansData(strategiquePlans);
    }
  }, [strategiquePlans]);

  const onDataFilter = (keyWord, hear) => {
    let filteredText = strategiquePlans;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (hear) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );
    }

    setStrategiquePlansData(filteredText);
    // console.log("On research", filteredText);
  }

  React.useEffect(() => {
    dispatch(globalProjectData("plan-strategique-2030"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Plans stratégiques 20-30"  onFilter={onDataFilter} />
      <GlobalCard textData={strategiquePlansData} documentData={strategiquePlansData} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default StrategiquePlan2030;
