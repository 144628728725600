import React from "react";

import "../App.css";
import "../theme.css";
import closeIcon from "../../src/assets/images/close.png"

const SingleTutelDetail = ({ data, close }) => {

  return (
    <div className="container pt-11">
      <div className="row ">
        <div className="offset-1 col-lg-9 mx-auto">
          <div className="bloc-detail position-relative" >
            <img className="" src={closeIcon} style={{ with: "32px", height: "32px", position: "absolute", right: "-16px", top: "-16px" }} alt="close" onClick={() => { close(null) }} />
            <div className=" d-flex align-items-center mb-5">
              <div className="mr-3" >
                <div className="circle-img-container">
                  <img
                    src={data?.media.length > 0 ? data?.media[0]?.original_url : null}
                    className="img-fluid rounded-circle"
                    alt={data?.sigle}
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>

              <div className="ml-3">
                <h2>
                  {data?.sigle}
                </h2>
                <p>
                  {data?.name}
                </p>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between py-2 mb-3 ">
              <h5>
                <strong>Directeur Général :</strong>{" "}
                {data?.dirigant || data?.dirigeant || data?.sigle}
              </h5>
            </div>
            <div className="text text-break">
              <p>
                {
                  data?.description}
                <br />
                {data?.content}
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTutelDetail;
