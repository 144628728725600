import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";

import "../App.css";
import "../theme.css";

import SingleTutelDetail from "./SingleTutelDetail";
import Skeleton from "react-loading-skeleton";

const CardTutelle = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [cardDetails, setCardDetails] = useState(null);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  const renderContentBlock = (delay, key) => {
    return (
      <div
        key={key}
        style={{ animationDelay: `${delay}s` }}
        className="col-lg-6 mb-4"
      >
        <div className="entity">
          <div className="d-flex align-items-center">
            <div className="">
              <div style={{ width: "100px", height: "100px" }}>
                <img
                  src={data[key]?.media.length > 0 ? data[key]?.media[0]?.original_url : null}
                  className="img-fluid rounded-circle"
                  alt={data[key]?.sigle}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            </div>

            <div className="ml-4">
              <h3>{data[key]?.sigle}</h3>
              <p>{data[key]?.name}</p>
              <button
                className="link-fill link-btn"
                onClick={() => {
                  setCardDetails(data[key]);
                }}
              >
                Lire <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLoadingBlock = (delay) => {
    return (
      <div style={{ animationDelay: `${delay}s` }} className="container mt-4">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <Skeleton height={100} />
          </div>
          <div className="col-lg-6 mb-4">
            <Skeleton height={100} />
          </div>
          <div className="col-lg-6 mb-4">
            <Skeleton height={100} />
          </div>
          <div className="col-lg-6 mb-4">
            <Skeleton height={100} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && renderLoadingBlock(1000)}
      {!loading && cardDetails == null && (
        <div className="container mt-4">
          <div className="row">
            {data?.length === 0 && (
              <p className="text-center pt-11">
                Aucune donnée disponible pour le moment. Veuillez réessayer plus
              </p>
            )}
            {data?.map((_, index) => {
              return renderContentBlock(0, index);
            })}
          </div>
        </div>
      )}

      {cardDetails !== null && (
        <SingleTutelDetail data={cardDetails} close={setCardDetails} />
      )}
    </>
  );
};

export default CardTutelle;
