import React, { useState } from "react";
import "../App.css";
import "../theme.css";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const CardCurrent = ({onFilter}) => {

  const [keyWord, setKeyWord] = useState("");
  const [hear, setHear] = useState("");

  return (
    <Row className="mb-3">
      <Col lg={5}>
        <Form.Group controlId="formGridText">
          <Form.Label>Mots clés</Form.Label>
          <Form.Control
            type="text"
            placeholder="Saisissez les mots clés"
            className="w-100"
            value={keyWord}
            onChange={(e) => { setKeyWord(e.target.value) }}
          />
        </Form.Group>
      </Col>

      <Col lg={5}>
        <Form.Group controlId="formGridDate">
          <Form.Label>Année</Form.Label>
          <Form.Control type="date"
            placeholder="aaa"
            className="w-100"
            value={hear}
            onChange={(e) => { setHear(e.target.value) }} />
        </Form.Group>
      </Col>

      <Col lg={2} className="text-center">
        <Form.Group controlId="formGridSubmit">
          <Button type="submit" onClick={()=> onFilter(keyWord, hear)}>Rechercher</Button>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default CardCurrent;
