import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { organismAndFonctionalityData } from "../redux/actions/action";

const OrganisationAdFonctionality = () => {
  let organisationAndFonctionality = useSelector(
    (state) => state.organismAndFonctionality
  );
  const dispatch = useDispatch();
  const [organisationAndFonctionalityDocument, setOrganisationAndFonctionalityDocument] = useState();
  const [organisationAndFonctionalityText, setOrganisationAndFonctionalityText] = useState();

  console.log("organisationAndFonctionality: ", organisationAndFonctionality);

  let organasationAndFonctionalities =
    organisationAndFonctionality?.organismAndFonctionalities?.data;

  useEffect(() => {
    // Filter the initial data on component mount
    if (organasationAndFonctionalities) {
      filterData(organasationAndFonctionalities);
    }
  }, [organasationAndFonctionalities]);

  const filterData = (data) => {
    const filteredText = data.filter((item) => item.type === "text");
    const filteredDocument = data.filter((item) => item.type === "document");
    setOrganisationAndFonctionalityText(filteredText);
    setOrganisationAndFonctionalityDocument(filteredDocument);
    console.log("Data*******", filteredText);
  };


  const onDataFilter = (keyWord, hear) => {
    let filteredText = organasationAndFonctionalities.filter((item) => item.type === "text");
    let filteredDocument = organasationAndFonctionalities.filter((item) => item.type === "document");

    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );

      filteredDocument = filteredDocument?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );

    }

    if (hear) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );

      filteredDocument = filteredDocument?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );
    }

    setOrganisationAndFonctionalityText(filteredText);
    setOrganisationAndFonctionalityDocument(filteredDocument);
    // console.log("On research", currentSituationText);
  }

  useEffect(() => {
    dispatch(organismAndFonctionalityData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Organisations et fonctionnements" onFilter={onDataFilter} />
      <GlobalCard
        documentData={organisationAndFonctionalityText}
        textData={organisationAndFonctionalityDocument}
      />
      <NewLetter />
      <Footer />
    </>
  );
};

export default OrganisationAdFonctionality;
