import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { juriCategoryDocumentData } from "../redux/actions/action";

const Observatory = () => {
  let observatoire = useSelector((state) => state.juriCategoryDocument);
  const dispatch = useDispatch();

  const [observatoiresDocument, setObservatoiresDocument] = useState();
  const [observatoiresText, setObservatoiresText] = useState();

  let observatoires = observatoire?.juriCategoryDocuments?.data?.document;

  console.log("observatoires: ", observatoires);

  useEffect(() => {
    if (observatoires) {
      filterData(observatoires);
    }
  }, [observatoires]);

  const filterData = (data) => {
    const filteredText = data.filter((item) => item.type === "text");
    const filteredDocument = data.filter((item) => item.type === "document");
    setObservatoiresText(filteredText);
    setObservatoiresDocument(filteredDocument);
  };

  const onDataFilter = (doc, keyWord, starthear, endhear) => {
    let filteredText = observatoires.filter((item) => item.type === "text");
    let filteredDocument = observatoires.filter((item) => item.type === "document");

    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
      filteredDocument = filteredDocument?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        console.log(timestampT2);
        return timestampT2 >= timestampT1
      }
      );
      filteredDocument = filteredDocument?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        console.log(timestampT2);
        return timestampT2 <= timestampT1
      }
      );
      filteredDocument = filteredDocument?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        return timestampT2 <= timestampT1
      }
      );
    }

    setObservatoiresText(filteredText);
    setObservatoiresDocument(filteredDocument);
    console.log("On research", filteredText);
  }

  useEffect(() => {
    dispatch(juriCategoryDocumentData("observatoire"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Observatoire" onFilter={onDataFilter} />
      <GlobalCard
        textData={observatoiresText}
        documentData={observatoiresDocument}
      />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Observatory;
